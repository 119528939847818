// scroll-module.js
import Lenis from "lenis";

const lenisDefault = (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t));

window.scrollWrap = document.querySelector(".page-w");

function handleEditor(onEditorView = null) {
  const webflowLs = localStorage.getItem("WebflowEditor");

  console.log(webflowLs);

  if (webflowLs) {
    if (onEditorView !== null) onEditorView();
    console.log("Webflow Editor View");
    return true;
  } else {
    return false;
  }
}

function injectCSS(string) {
  const style = document.createElement("style");
  style.textContent = string;
  document.head.append(style);
}

const customcss = injectCSS(`
  .lenis.lenis-smooth {
    scroll-behavior: auto;  
  }
  html.lenis {
    height: auto;
  }
`);

const params = {
  wrapper: window,
  duration: 0.85,
  easing: lenisDefault,
  orientation: "vertical",
  smoothWheel: true,
  smoothTouch: false,
  touchMultiplier: 1.2,
  infinite: false,
  useOverscroll: true,
  useControls: true,
  useAnchor: true,
  useRaf: true,
  autoResize: true,
};

class Scroll extends Lenis {
  constructor(params) {
    super(params);
    this.params = params;
    this.isActive = true;
    this.lastScrollTop = 0;
    this.init();
    this.call = {
      stop: () => this.stop(),
      start: () => this.start(),
    };
    this.scrollMonitor = this.scrollMonitor.bind(this);
  }

  init() {
    this.config();
    this.render();
    if (this.params.useRaf) {
      this.y = window.scrollY;
      this.max = window.innerHeight;
      this.speed = 0;
      this.percent = this.y / (document.body.scrollHeight - window.innerHeight);
      this.direction = 0;
      this.on("scroll", (e) => this.outScroll(e));
    }
    handleEditor(this.destroy.bind(this));
    this.scrollMonitor();
  }

  reconfig() {
    if (this.scrollinks)
      this.scrollinks.forEach((item) => (item.onclick = null));
    this.config();
  }

  config() {
    if (this.params.useAnchor)
      this.scrolllinks = [
        ...document.querySelectorAll("[data-scrolllink]"),
      ].map((item) => {
        const target = document.querySelector(item.dataset.scrolllink);
        if (target) {
          item.onclick = (e) => {
            e.preventDefault();
            this.scrollTo(target);
          };
        }
        return item;
      });

    if (this.params.useOverscroll)
      [...document.querySelectorAll('[data-scroll="overscroll"]')].forEach(
        (item) => item.setAttribute("onwheel", "event.stopPropagation()")
      );

    if (this.params.useControls) {
      [...document.querySelectorAll('[data-scroll="stop"]')].forEach((item) => {
        item.onclick = () => {
          this.stop();
          this.isActive = false;
        };
      });

      [...document.querySelectorAll('[data-scroll="start"]')].forEach(
        (item) => {
          item.onclick = () => {
            this.start();
            this.isActive = true;
          };
        }
      );

      [...document.querySelectorAll('[data-scroll="toggle"]')].forEach(
        (item) => {
          item.onclick = () => {
            if (this.isActive) {
              this.stop();
              this.isActive = false;
            } else {
              this.start();
              this.isActive = true;
            }
          };
        }
      );
    }
  }

  render(time) {
    this.raf(time);
    window.requestAnimationFrame(this.render.bind(this));
  }

  outScroll({ scroll, limit, velocity, progress, direction }) {
    this.y = scroll || 0;
    this.max = limit || window.innerHeight;
    this.speed = velocity || 0;
    this.percent = progress || 0;
    this.direction = direction || 0;

    if (this.params.useRaf) {
      window.dispatchEvent(
        new CustomEvent("sscroll", {
          detail: {
            y: this.y,
            max: this.max,
            speed: this.speed,
            percent: this.percent,
            direction: this.direction,
          },
        })
      );
    }
    this.scrollMonitor();
    this.updateNavVisibility();
  }

  scrollMonitor() {
    const html = document.documentElement;
    if (this.y > 100) {
      html.classList.add("is--scrolled");
    } else {
      html.classList.remove("is--scrolled");
    }
  }

  updateNavVisibility() {
    const navElements = document.querySelectorAll("[data-nav]");
    if (this.y > this.lastScrollTop) {
      // Scrolling down
      navElements.forEach((el) => el.setAttribute("data-nav", "hidden"));
    } else {
      // Scrolling up
      navElements.forEach((el) => el.setAttribute("data-nav", ""));
    }
    this.lastScrollTop = this.y <= 0 ? 0 : this.y; // For Mobile or negative scrolling
  }

  renderWebflow(time) {}
}

const SScroll = new Scroll(params);

export { SScroll };

export function lenisResize() {
  SScroll.resize();
}

export function lenisToTop(duration = 0) {
  const options = {};
  if (duration === 0) {
    options.immediate = true;
  } else {
    options.duration = duration;
  }
  SScroll.scrollTo(0, options);
}

// Make SScroll available globally
if (typeof window !== "undefined") {
  window.SScroll = SScroll;
}

// test-script.js
document.addEventListener("DOMContentLoaded", () => {
  // console.log("Testing Scroll Module");

  // Test 1: Check if SScroll is available globally
  if (window.SScroll) {
    // console.log("Test 1 Passed: SScroll is available globally");
  } else {
    console.error("Warning: SScroll is not available globally");
  }

  // Test 2: Check if SScroll methods are accessible
  if (
    typeof window.SScroll.start === "function" &&
    typeof window.SScroll.stop === "function"
  ) {
    // console.log("Test 2 Passed: SScroll methods (start/stop) are accessible");
  } else {
    console.error("Warning: SScroll methods (start/stop) are not accessible");
  }

  // // Test 3: Test scroll functionality
  // const testScrollButton = document.createElement('button');
  // testScrollButton.textContent = 'Test Scroll to Bottom';
  // testScrollButton.style.position = 'fixed';
  // testScrollButton.style.top = '10px';
  // testScrollButton.style.left = '10px';
  // testScrollButton.style.zIndex = '9999';

  // testScrollButton.addEventListener('click', () => {
  //   window.SScroll.scrollTo(document.body.scrollHeight, { duration: 2000 });
  //   console.log('Test 3: Initiated scroll to bottom. Check for smooth scrolling.');
  // });

  // document.body.appendChild(testScrollButton);

  // Test 4: Listen for custom scroll event
  // window.addEventListener('sscroll', (e) => {
  //   console.log('Test 4: Custom scroll event detected', e.detail);
  // });

  // console.log(
  //   "Scroll Module tests setup complete. Click the test button to verify smooth scrolling."
  // );
});

// // scrollConfig for Fixed height window
// window.ScrollConfig = {
//   scrollWrapSelector: "[data-scroll-wrap]",

//   getScrollWrap() {
//     return document.querySelector(this.scrollWrapSelector);
//   },

//   setupScrollTrigger() {
//     const scrollWrap = this.getScrollWrap();
//     const shouldUseCustomScroller = window.isTabletOrBelow;

//     ScrollTrigger.getAll().forEach((st) => {
//       st.scroller = shouldUseCustomScroller ? scrollWrap : window;
//       st.refresh();
//     });

//     // Force ScrollTrigger to recalculate all scrollers and trigger positions
//     ScrollTrigger.refresh();
//   },

//   init() {
//     // Initial setup
//     this.setupScrollTrigger();

//     // Update on window resize
//     window.addEventListener("resize", () => this.setupScrollTrigger());
//   },
// };
